@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --bg-color: #0c0c0c;
  --primary-color: #0d0d0d;
  --secondary-color: #fff;
  --text-color: #fff;
  --text-color-2: #fff;
  --text-color-3: #fbff00;
  --overlay-color: rgb(12 12 12 / 63%);
  --progress-bar-bg: rgba(255, 255, 255, 0.6); }

[data-theme="light"] {
  --bg-color: #ffffff;
  --primary-color: #ffffff;
  --secondary-color: #000;
  --text-color: #000;
  --text-color-2: #000;
  --text-color-3: #fbff00;
  --overlay-color: rgb(255 255 255 / 70%);
  --progress-bar-bg: rgba(0, 0, 0, 0.6); }

html,
body {
  height: 100%; }

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
  border-left: 10px solid var(--primary-color);
  border-right: 10px solid var(--primary-color); }

ul {
  list-style: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Merriweather; }

a,
a:hover {
  color: var(--text-color); }

p {
  word-break: break-word;
  hyphens: auto; }

.ovhidden {
  overflow: hidden; }

.text_2,
.text_2:hover {
  color: var(--text-color-2); }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.color_pr {
  color: var(--primary-color) !important; }

.color_sec {
  color: var(--secondary-color); }

@media only screen and (max-width: 991px) {
  .s_c {
    padding-top: 40px; } }

.page-enter {
  transform: translateY(100%); }

.page-enter-active {
  transform: translateY(0%);
  transition: all 400ms ease-out; }

.page-exit {
  transform: translateY(0%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0; }

.page-exit-active {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transform: translateY(-130%);
  transition: all 400ms ease-out;
  background: var(--bg-color); }

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px; } }

.sec_sp {
  margin-bottom: calc(3rem + 5.128vw); }

.grid {
  display: grid;
  gap: 50px; }
  .grid .grid-item {
    background: var(--secondary);
    border-radius: 0;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .grid .grid-item .grid-title {
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0 20px;
    font-family: "Noto Sans";
    text-align: center; }
  .grid .grid-item svg {
    height: 40px;
    width: 40px;
    color: var(--text-color); }

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)); }

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)); }

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)); }

.site__header {
  top: 10px;
  padding-left: 10px;
  padding-right: 10px; }

.menu__button {
  color: var(--text-color); }

.menu__button:focus,
.menu__button:hover {
  color: var(--text-color);
  box-shadow: unset; }

.menu__button svg {
  width: 2em;
  height: 2em;
  fill: var(--text-color-2);
  color: var(--text-color-2); }

.nav_ac {
  padding: 5px 15px;
  margin: 0;
  border: unset;
  background: var(--primary-color);
  font-size: 1.25rem;
  font-family: Merriweather;
  color: var(--text-color-2);
  line-height: 2;
  height: 50px;
  font-weight: bold;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center; }

.nav_ac:hover {
  color: var(--text-color-2); }

.br-top,
.br-bottom,
.br-right,
.br-left {
  position: fixed;
  z-index: 999999;
  background: var(--primary-color); }

.br-top {
  top: 0;
  height: 10px;
  left: 0;
  width: 100%; }

.br-bottom {
  bottom: 0;
  left: 0;
  height: 10px;
  width: 100%; }

.br-right {
  width: 10px;
  right: 0;
  top: 0;
  height: 100%; }

.br-left {
  width: 10px;
  left: 0;
  top: 0;
  height: 100%; }

.cortina__wrapper-menu {
  position: relative;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 3em;
  height: 100%;
  overflow-y: auto; }

.site__navigation {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  visibility: hidden; }

.menu__opend {
  visibility: visible !important; }

.main__menu_ul,
.menu_right {
  opacity: 0;
  position: relative;
  transition: 0.5s;
  transition-delay: 0s;
  visibility: hidden;
  z-index: 100; }

.menu_right {
  text-align: center; }

.site__navigation.menu__opend .main__menu_ul,
.site__navigation.menu__opend .menu_right {
  opacity: 1;
  transition-delay: 0.6s;
  visibility: visible; }

.site__navigation .main__menu_ul li {
  list-style: none;
  margin: 10px 0; }

.site__navigation .main__menu_ul li a {
  color: var(--text-color);
  display: block;
  font-size: 2.5rem;
  text-decoration: none; }

.bg__menu {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  will-change: transform;
  transform: translateY(-100%);
  transition: 0.5s ease all; }

.menu__opend .bg__menu {
  transform: translateY(0); }

.menu__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden auto; }

.the_menu {
  padding-top: 20vh;
  padding-bottom: 20vh;
  padding-left: 0; }

@media (min-width: 992px) {
  .menu__container {
    margin-left: 33.3333%; }
  .the_menu {
    padding-top: 10vh;
    padding-bottom: 10vh; } }

.the_menu .menu_item > a {
  color: var(--text-color-2);
  line-height: 1;
  font-size: 2rem;
  display: inline-block;
  position: relative;
  transition: color 250ms cubic-bezier(0, 0, 0.58, 1) 0s;
  padding: 4px 0px;
  text-decoration: none;
  font-family: Merriweather; }

.the_menu .menu_item > a:hover {
  color: var(--text-color-3); }

@media (min-width: 768px) {
  .the_menu .menu_item > a {
    font-size: 4.8vw; } }

.menu_footer {
  bottom: 0;
  font-family: Merriweather;
  font-size: 14px; }

.menu_footer a {
  color: var(--text-color-2);
  margin-right: 10px;
  text-decoration: none; }

.stick_follow_icon {
  top: 50%;
  left: 30px;
  width: 20px;
  height: 200px;
  position: fixed;
  margin-top: -100px; }

.stick_follow_icon ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.stick_follow_icon svg {
  width: 1.3em;
  height: 1.3em;
  fill: var(--text-color); }

.stick_follow_icon p {
  top: 70px;
  left: -24px;
  width: 68px;
  height: 20px;
  color: var(--text-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  white-space: nowrap;
  position: relative;
  transform: rotate(-90deg); }

.stick_follow_icon ul li {
  display: block;
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.3s; }

.stick_follow_icon p:after {
  top: 9px;
  right: -48px;
  width: 40px;
  height: 1px;
  content: "";
  display: block;
  position: absolute;
  background-color: var(--text-color); }

@media only screen and (max-width: 991px) {
  .stick_follow_icon {
    width: unset;
    height: unset;
    position: static;
    margin-top: unset;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 40px 0;
    align-items: center; }
  .stick_follow_icon p:after {
    display: none; }
  .stick_follow_icon p {
    top: unset;
    left: unset;
    width: unset;
    height: unset;
    white-space: nowrap;
    position: relative;
    transform: unset;
    font-size: 17px; }
  .stick_follow_icon ul {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  .stick_follow_icon ul li {
    display: inline;
    margin-bottom: 29px;
    margin-right: 10px; } }

.theme_toggler {
  background: var(--primary-color);
  z-index: 999999999;
  left: 10px;
  background: var(--primary-color);
  display: flex;
  height: 50px;
  align-items: center;
  padding-left: 10px; }

.theme_toggler svg {
  width: 2em;
  height: 2em; }

.section-title {
  font-size: 45px; }

.service__title {
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between; }
  .service__title .m-0 {
    margin-bottom: 0px; }
  .service__title h5 {
    font-weight: 500; }
  .service__title.border-title {
    border-bottom: solid 2px var(--secondary-color); }

.service_desc {
  padding: 8px 0; }

.service-section .service-category-title {
  padding-bottom: 4px; }

/*! CSS Used keyframes */
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0); } }

.bg-transparent {
  --bs-table-bg: transparent !important; }

.progress-title {
  font-size: 16px;
  font-weight: 700;
  margin: 15px 0 20px;
  font-family: "Noto Sans"; }

.progress {
  height: 5px;
  background: var(--progress-bar-bg);
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 30px;
  overflow: visible; }

.progress .progress-bar-full {
  position: relative;
  background: var(--text-color);
  animation: animate-positive 2s;
  overflow: visible;
  opacity: 0.9; }

.progress .progress-value {
  position: absolute;
  top: -30px;
  right: 8px;
  font-size: 17px;
  font-weight: bold;
  font-style: italic;
  color: var(--text-color); }

@-webkit-keyframes animate-positive {
  0% {
    width: 0%; } }

@keyframes animate-positive {
  0% {
    width: 0%; } }

.table td,
.table th {
  color: var(--text-color); }

.t_border {
  border-color: var(--text-color) !important; }

.contact__form .form-control {
  padding: 1.375rem 0.75rem;
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--bg-color);
  border-radius: 0 !important;
  border: 1px solid var(--secondary-color); }
  .contact__form .form-control::placeholder {
    color: var(--text-color); }

.contact__form input.form-control {
  margin-bottom: 2em;
  height: calc(2.5em + 0.75rem + 2px); }

button.btn.ac_btn:hover {
  color: var(--secondary-color); }

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  z-index: 999999999;
  background: var(--text-color);
  transform: translateX(100%);
  animation: shift-rightwards 1s ease-in-out infinite;
  animation-delay: 0.3s; }

@keyframes shift-rightwards {
  0% {
    transform: translateX(-100%); }
  40% {
    transform: translateX(0%); }
  60% {
    transform: translateX(0%); }
  100% {
    transform: translateX(100%); } }

section {
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  -webkit-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in; }

.who_am_I {
  font-family: Cinzel; }

.has-first-color {
  color: var(--primary-color); }

.btn-portfolio {
  background: var(--primary-color);
  border-radius: 0; }

.btn-portfolio a {
  color: #000;
  text-decoration: none; }

.btn-about a {
  color: var(--text-color);
  text-decoration: none; }

.intro_sec {
  height: calc(100vh - 60px);
  min-height: 700px;
  height: 100vh;
  margin-top: -60px; }

@media (max-width: 991.98px) {
  .intro_sec {
    display: block;
    height: auto !important; } }

.intro_sec .text,
.intro_sec .h_bg-image {
  width: 50%; }

.intro_sec .h_bg-image {
  height: 100vh !important; }

@media (max-width: 991.98px) {
  .intro_sec .text,
  .intro_sec .h_bg-image {
    width: 100%; }
  .intro_sec .h_bg-image {
    height: 50vh !important; } }

.intro_sec .intro {
  max-width: 450px;
  margin: 0 auto; }

@media (max-width: 991.98px) {
  .intro_sec .intro {
    max-width: 700px;
    padding-left: 20px;
    padding-right: 20px; } }

.intro_sec .intro .feature .wrap-icon {
  background: 0 0 !important;
  width: auto;
  height: auto;
  margin-bottom: 0; }

.intro_sec .intro .feature .wrap-icon svg {
  color: #5cccc9; }

.intro_sec .text h1 {
  font-size: 30px;
  margin-bottom: 50px;
  font-weight: 700; }

.intro_sec .text h3 {
  font-size: 16px;
  font-weight: 700; }

.intro_sec .h_bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 700px;
  position: relative; }

.ac_btn {
  padding: 4px 19px;
  color: var(--secondary-color);
  position: relative;
  border: var(--secondary-color) 2px solid;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  cursor: pointer;
  border-radius: 0;
  margin-right: 20px; }

.ac_btn a {
  text-decoration: none; }

.ac_btn:hover {
  box-shadow: 8px 8px 0px var(--text-color), -8px -8px 0px var(--text-color); }

.ac_btn:hover .one {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px); }

.ac_btn:hover .two {
  transform: translate3d(0px, 0px, 0px); }

.ac_btn:hover .three {
  transform: translate3d(0px, 0px, 0px); }

.ac_btn:hover .four {
  transform: translate3d(0px, 0px, 0px); }

.ac_btn .ring {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  transform: translate3d(0px, 90px, 0px); }

.ac_btn .one {
  background-color: #000;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3;
  z-index: -4; }

.ac_btn .two {
  background-color: var(--primary-color);
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3; }

.ac_btn .three {
  background-color: var(--secondary-color);
  z-index: -2;
  transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3; }

#button_p {
  background: var(--secondary-color);
  color: var(--primary-color); }

#button_h:hover {
  color: var(--primary-color); }

.intro_sec .h_bg-image .block {
  bottom: 50vh;
  max-width: 220px;
  padding: 30px;
  left: -110px;
  position: absolute;
  width: 250px;
  height: 220px;
  border-radius: 100%;
  /* mix-blend-mode: multiply; */
  /* background-color: #2f514e; */
  z-index: 9999999999;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; }

@media (max-width: 991.98px) {
  .intro_sec .h_bg-image {
    height: 600px;
    min-height: 40vh;
    margin-bottom: 30px; }
  .intro_sec .h_bg-image .block {
    top: unset;
    bottom: 0;
    width: 100%;
    margin: 0;
    left: 50%;
    transform: translate(-50%, 50%); } }

.intro_sec .h_bg-image .block .wrap-icon {
  font-size: 40px;
  color: var(--text-color);
  margin-bottom: 10px;
  display: block; }

.intro_sec .h_bg-image .block h3 {
  font-size: 14px;
  color: var(--text-color);
  letter-spacing: 0.1rem;
  font-weight: 700;
  margin-bottom: 15px; }

.intro_sec .h_bg-image .block p {
  font-size: 14px;
  line-height: 1.3;
  color: var(--text-color); }

.intro_sec .h_bg-image {
  /* filter: saturate(0.5); */ }

.animate-open-image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.max-w-96 {
  max-width: 364px; }

.markdow-style ul {
  list-style: disc; }

.markdow-style ol {
  list-style: auto; }
